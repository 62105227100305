<template>
    <div class="Price" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px">
                <el-row>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                    <el-col :span="8">
                        <ef-review-status v-model="form.reviewStatus" />
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="关键字" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" v-if="hasPrivilege('menu.goods.price.open')" @click="handleQuery" size="small">
                查询
            </el-button>
            <el-button type="primary" v-if="hasPrivilege('menu.goods.price.create')" @click="handleCreate" size="small">
                新建
            </el-button>
            <el-button
                type="primary"
                v-if="hasPrivilege('menu.goods.price.create')"
                @click="handleChangePrice"
                size="small"
            >
                批量调价
            </el-button>
            <el-button type="primary" v-if="hasPrivilege('menu.goods.price.export')" @click="handleExport" size="small">
                导出
            </el-button>
            <el-button
                type="primary"
                :disabled="selections.length == 0"
                v-if="hasPrivilege('menu.goods.price.review')"
                @click="showBatchReview(selections)"
                size="small"
            >
                批量审核
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" :baseUrl="baseUrl" @completed="handleQuery" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                ref="table"
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="430"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column prop="code" v-if="showColumn('code')" label="编号" width="190" />
                <el-table-column prop="deptName" v-if="showColumn('deptName')" label="机构名称" min-width="160" />
                <el-table-column prop="name" v-if="showColumn('name')" label="商品名称" min-width="160" />
                <el-table-column
                    prop="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                    key="reviewStatus"
                    label="状态"
                    width="80"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="factoryPrice"
                    key="factoryPrice"
                    label="出厂价"
                    width="80"
                    v-if="hasPrivilege('biz.price.factory.query') && showColumn('factoryPrice')"
                >
                    <template slot-scope="scope">
                        <span>{{ fmtThree(scope.row.factoryPrice) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="wholeSalePrice"
                    key="wholeSalePrice"
                    label="批发价"
                    v-if="hasPrivilege('biz.price.wholeSale.query') && showColumn('wholeSalePrice')"
                    width="80"
                >
                    <template slot-scope="scope">
                        <span>{{ fmtTwo(scope.row.wholeSalePrice) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="retailPrice"
                    key="retailPrice"
                    label="零售价"
                    v-if="hasPrivilege('biz.price.retail.query') && showColumn('retailPrice')"
                    width="80"
                >
                    <template slot-scope="scope">
                        <span>{{ fmtTwo(scope.row.retailPrice) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="creator" v-if="showColumn('creator')" label="创建人" width="110" />
                <el-table-column prop="createTime" v-if="showColumn('createTime')" label="创建时间" width="140" />
                <el-table-column
                    label="操作"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                    min-width="170"
                    header-align="center"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            v-if="hasPrivilege('menu.goods.price.open')"
                            @click="rowDetail(scope.row)"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            v-if="hasPrivilege('menu.goods.price.edit')"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            :disabled="scope.row.disableEditBtn"
                        >
                            编辑
                        </el-button>
                        <el-button
                            size="mini"
                            v-if="hasPrivilege('menu.goods.price.delete')"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            :disabled="scope.row.disableDeleteBtn"
                        >
                            删除
                        </el-button>
                        <el-button
                            type="primary"
                            v-if="hasPrivilege('menu.goods.price.review')"
                            @click="showReviewDialog(scope.row)"
                            size="mini"
                            :disabled="scope.row.disableReviewBtn"
                        >
                            审核
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination :total="total" @pageChange="pageChange" :default-limit="form.limit" />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfPagination from 'components/EfPagination';
import EfReviewBiz from 'components/EfReviewBiz';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import EfReviewStatus from 'components/EfReviewStatus';
import StockBizCommon from 'js/StockBizCommon';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'Price',
    components: {
        EfPagination,
        EfReviewBiz,
        EfEndDate,
        CheckTableShowColumnDialog,
        EfStartDate,
        EfDeptTypeAndDeptSelect,
        EfReviewStatus,
    },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            baseUrl: this.$efApi.goodsPriceChangeApi.baseUrl,
            form: {
                deptType: '',
                deptCode: '',
                search: '',
                reviewStatus: '',
                startTime: '',
                endTime: '',
                page: 1,
                limit: 100,
            },
            total: 0,
            tableData: [],
            selections: [],
        };
    },
    mounted() {},
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    computed: {},
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        async handleQuery() {
            const rst = await this.$efApi.goodsPriceChangeApi.pageExtend(this.form);
            this.tableData = rst.data.map((e) => {
                return {
                    ...e,
                    //根据状态设置操作按钮禁用或启用
                    disableEditBtn: !StockBizCommon.validateCanEdit(e.reviewStatus),
                    disableDeleteBtn: !StockBizCommon.validateCanDelete(e.reviewStatus),
                    disableReviewBtn: !StockBizCommon.validateCanReview(e.reviewStatus),
                };
            });
            this.total = rst.count;
        },
        handleCreate() {
            Util.nameJump(this, 'menu.goods.price.create', ['商品管理', '商品调价', '新建调价']);
        },
        handleChangePrice() {
            Util.nameJump(this, 'menu.goods.price.batchChangePrice', ['商品管理', '商品调价', '批量调价']);
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '商品调价', '/goods/price/exportExtend', this.form, codes);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.goods.price.detail', ['商品管理', '商品调价', '价格详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.goods.price.edit', ['商品管理', '商品调价', '价格编辑'], {
                form: row,
                code: row.code,
            });
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        handleSelectionChange(val) {
            this.selections = val;
        },
        handleSelectType(typeIndex) {
            this.form.deptCode = this.meta.types[typeIndex].children[0].code;
            this.form.deptType = this.meta.types[typeIndex].type;
            this.handleSelectDept(this.form.deptCode);
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
            if (deptType) {
                this.handleQuery();
            }
        },
        showBatchReview(row) {
            this.$refs.review.open(row);
        },
        showReviewDialog(row) {
            this.$refs.review.open([row]);
        },
        async handleDelete(row) {
            await this.$efApi.goodsPriceChangeApi.deleteBiz(row.code);
            this.handleQuery();
        },
    },
};
</script>

<style scoped>
.Price .el-form-item {
    margin-bottom: 0;
}
</style>
